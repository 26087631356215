import type { User } from '@fieldmargin/webapp-auth';
import { fetchUser, logout } from '@fieldmargin/webapp-auth';
import type { FarmUser } from '@fieldmargin/webapp-farms';
import { FarmUserRole } from '@fieldmargin/webapp-farms';
import { createLogger } from '@fieldmargin/webapp-reporting';
import { analytics } from '@notes-web/analytics';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { fetchIntercomUserHashApi } from 'auth/account-api';
import Promise from 'bluebird';
import config from 'lib/config';
import { MeasurementUnit } from 'lib/MeasurementUnit';
import type UserPreferences from 'lib/model/UserPreferences';
import LocalStorageHelper from 'lib/storage/LocalStorageHelper';
import { defaultTo } from 'ramda';
import { createSelector } from 'reselect';
import { resetForAppContainer } from 'system/app-state';
import type { AppState } from 'system/store';
const logger = createLogger('users.users-state');

export const getUserAccount = () =>
    fetchUser()
        .then((user) => Promise.props({ user, userHash: fetchIntercomUserHashApi() }))
        .then(({ user, userHash }) => {
            if (user !== null) {
                analytics.identify(
                    `${config.get('segmentIdPrefix')}${user.id}`,
                    {
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                    },
                    { Intercom: { user_hash: userHash } }
                );
            }
            return user;
        });

interface UserState {
    user: User | null;
    preferences: UserPreferences | null;
}

const initialState: UserState = {
    user: null,
    preferences: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
        },
        setUserPreferences: (state, action: PayloadAction<UserPreferences>) => {
            state.preferences = action.payload;
        },
        setUserAcceptedTerms: (state) => {
            if (state.user !== null) {
                state.user.acceptedLatestTermsVersion = true;
            }
        },
    },
    extraReducers: (builder) =>
        builder.addCase(resetForAppContainer.toString(), () => initialState),
});

export const signOut = () => () =>
    logout()
        .then(() => {
            LocalStorageHelper.removeItem('lastFarmUuid');
            LocalStorageHelper.removeItem('agreenaUser');
            window.location.href = '/'; // reloads page to clean up any ajax requests.
        })
        .catch((e) => {
            logger.error('Unable to sign out', e);
            return null;
        });

export const { setUser, setUserPreferences, setUserAcceptedTerms } = userSlice.actions;
export const userReducer = userSlice.reducer;

/**-----------------------------
 * Selectors
 -----------------------------*/
export const selectUserId = createSelector(
    (state: AppState) => state.user,
    (userState) => (userState.user as User).id
);

// Create a FarmUser object for the user with the owner value set to true
export const selectUserAsFarmOwner = createSelector(
    (state: AppState) => state.user.user,
    (user): FarmUser | null =>
        user !== null
            ? {
                  id: user.id,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  email: user.email,
                  owner: true,
                  role: FarmUserRole.OWNER,
                  active: true,
                  temporary: false,
                  pending: false,
              }
            : null
);

export const selectUserPreferences = createSelector(
    (state: AppState) => state.user.preferences,
    defaultTo<UserPreferences>({
        lengthMeasurementUnits: 'METRIC',
        areaMeasurementUnits: 'METRIC',
        temperatureMeasurementUnit: 'CELSIUS',
    })
);

export const selectUserAreaMeasurementUnit = createSelector(
    (state: AppState) => state.user.preferences,
    (preferences): MeasurementUnit =>
        preferences ? MeasurementUnit[preferences.areaMeasurementUnits] : MeasurementUnit.METRIC
);

export const selectUserLengthMeasurementUnit = createSelector(
    (state: AppState) => state.user.preferences,
    (preferences): MeasurementUnit =>
        preferences ? MeasurementUnit[preferences.lengthMeasurementUnits] : MeasurementUnit.METRIC
);

export const selectUserTemperatureUnit = createSelector(
    (state: AppState) => state.user.preferences,
    (preferences) => (preferences !== null ? preferences.temperatureMeasurementUnit : 'CELSIUS')
);
